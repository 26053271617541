var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { title: _vm.$t("lbl_customer_maintenance_report_with_price") } },
    [
      _c(
        "a-form",
        _vm._b(
          {
            attrs: { form: _vm.form, labelAlign: "left" },
            nativeOn: {
              submit: function($event) {
                $event.preventDefault()
                return _vm.onSubmit(false)
              }
            }
          },
          "a-form",
          _vm.formItemLayout,
          false
        ),
        [
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-item",
                    { attrs: { label: _vm.$t(_vm.formRules.branch.label) } },
                    [
                      _c(
                        "a-select",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: _vm.formRules.branch.decorator,
                              expression: "formRules.branch.decorator"
                            }
                          ],
                          attrs: {
                            name: _vm.formRules.branch.name,
                            placeholder: _vm.$t(
                              _vm.formRules.branch.placeholder
                            ),
                            showSearch: true,
                            "option-filter-prop": "children",
                            "filter-option": _vm.filterOption,
                            loading: _vm.loading.loadingBranch,
                            allowClear: true
                          },
                          on: {
                            search: function(value) {
                              return _vm.getBranch(value)
                            }
                          }
                        },
                        _vm._l(_vm.dataBranch, function(data, index) {
                          return _c(
                            "a-select-option",
                            { key: index, attrs: { value: data.id } },
                            [
                              _c(
                                "a-tooltip",
                                [
                                  _c("template", { slot: "title" }, [
                                    _vm._v(" " + _vm._s(data.name) + " ")
                                  ]),
                                  _vm._v(" " + _vm._s(data.name) + " ")
                                ],
                                2
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    {
                      attrs: {
                        label: _vm.$t(
                          _vm.formRules.customerMaintenanceNumber.label
                        )
                      }
                    },
                    [
                      _c(
                        "a-select",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value:
                                _vm.formRules.customerMaintenanceNumber
                                  .decorator,
                              expression:
                                "formRules.customerMaintenanceNumber.decorator"
                            }
                          ],
                          attrs: {
                            name: _vm.formRules.customerMaintenanceNumber.name,
                            placeholder: _vm.$t(
                              _vm.formRules.customerMaintenanceNumber
                                .placeholder
                            ),
                            showSearch: true,
                            "option-filter-prop": "children",
                            "filter-option": _vm.filterOption,
                            loading: _vm.loading.loadingCustomerMaintenance,
                            allowClear: true
                          },
                          on: {
                            search: function(value) {
                              return _vm.getListCustomerMaintenanceNumber(value)
                            }
                          }
                        },
                        _vm._l(_vm.dataListCustomerMaintenance, function(
                          data,
                          index
                        ) {
                          return _c(
                            "a-select-option",
                            {
                              key: index,
                              attrs: { value: data.documentNumber }
                            },
                            [
                              _c(
                                "a-tooltip",
                                [
                                  _c("template", { slot: "title" }, [
                                    _vm._v(
                                      " " + _vm._s(data.documentNumber) + " "
                                    )
                                  ]),
                                  _vm._v(
                                    " " + _vm._s(data.documentNumber) + " "
                                  )
                                ],
                                2
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: _vm.$t(_vm.formRules.woNumber.label) } },
                    [
                      _c(
                        "a-select",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: _vm.formRules.woNumber.decorator,
                              expression: "formRules.woNumber.decorator"
                            }
                          ],
                          attrs: {
                            name: _vm.formRules.woNumber.name,
                            placeholder: _vm.$t(
                              _vm.formRules.woNumber.placeholder
                            ),
                            showSearch: true,
                            "option-filter-prop": "children",
                            "filter-option": _vm.filterOption,
                            loading: _vm.loading.loadingWoNumber,
                            allowClear: true
                          },
                          on: {
                            search: function(value) {
                              return _vm.getWoNumber(value)
                            },
                            change: _vm.handleWorkOrderNumber
                          }
                        },
                        _vm._l(_vm.dataWoNumber, function(data, index) {
                          return _c(
                            "a-select-option",
                            {
                              key: index,
                              attrs: { value: data.documentNumber }
                            },
                            [
                              _c(
                                "a-tooltip",
                                [
                                  _c("template", { slot: "title" }, [
                                    _vm._v(
                                      " " + _vm._s(data.documentNumber) + " "
                                    )
                                  ]),
                                  _vm._v(
                                    " " + _vm._s(data.documentNumber) + " "
                                  )
                                ],
                                2
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: _vm.$t(_vm.formRules.csfNumber.label) } },
                    [
                      _c(
                        "a-select",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: _vm.formRules.csfNumber.decorator,
                              expression: "formRules.csfNumber.decorator"
                            }
                          ],
                          attrs: {
                            name: _vm.formRules.csfNumber.name,
                            placeholder: _vm.$t(
                              _vm.formRules.csfNumber.placeholder
                            ),
                            showSearch: true,
                            "option-filter-prop": "children",
                            "filter-option": _vm.filterOption,
                            loading: _vm.loading.loadingCsfNumber,
                            allowClear: true
                          },
                          on: {
                            search: function(value) {
                              return _vm.getListCsfNumber(value)
                            }
                          }
                        },
                        _vm._l(_vm.dataCsfNumber, function(data, index) {
                          return _c(
                            "a-select-option",
                            {
                              key: index,
                              attrs: { value: data.workOrderCSFNumber }
                            },
                            [
                              _c(
                                "a-tooltip",
                                [
                                  _c("template", { slot: "title" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s("" + data.workOrderCSFNumber) +
                                        " "
                                    )
                                  ]),
                                  _vm._v(
                                    " " +
                                      _vm._s("" + data.workOrderCSFNumber) +
                                      " "
                                  )
                                ],
                                2
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: _vm.$t(_vm.formRules.mechanic.label) } },
                    [
                      _c("SelectMechanic", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: _vm.formRules.mechanic.decorator,
                            expression: "formRules.mechanic.decorator"
                          }
                        ],
                        attrs: {
                          name: _vm.formRules.mechanic.name,
                          placeholder: _vm.$t(
                            _vm.formRules.mechanic.placeholder
                          ),
                          "label-in-value": ""
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-item",
                    {
                      attrs: {
                        "label-align": "right",
                        label: _vm.$t(_vm.formRules.customerName.label)
                      }
                    },
                    [
                      _c(
                        "a-select",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: _vm.formRules.customerName.decorator,
                              expression: "formRules.customerName.decorator"
                            }
                          ],
                          attrs: {
                            name: _vm.formRules.customerName.name,
                            placeholder: _vm.$t(
                              _vm.formRules.customerName.placeholder
                            ),
                            showSearch: true,
                            "option-filter-prop": "children",
                            "filter-option": _vm.filterOption,
                            loading: _vm.loading.loadingCustomerName,
                            allowClear: true
                          },
                          on: {
                            search: function(value) {
                              return _vm.getCustomerName(value)
                            }
                          }
                        },
                        _vm._l(_vm.dataCustomerName, function(data, index) {
                          return _c(
                            "a-select-option",
                            { key: index, attrs: { value: data.id } },
                            [
                              _c(
                                "a-tooltip",
                                [
                                  _c("template", { slot: "title" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          data.fullName.replace("null", "")
                                        ) +
                                        " "
                                    )
                                  ]),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        data.fullName.replace("null", "")
                                      ) +
                                      " "
                                  )
                                ],
                                2
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    {
                      attrs: {
                        "label-align": "right",
                        label: _vm.$t(_vm.formRules.unitCode.label)
                      }
                    },
                    [
                      _c(
                        "a-select",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: _vm.formRules.unitCode.decorator,
                              expression: "formRules.unitCode.decorator"
                            }
                          ],
                          attrs: {
                            name: _vm.formRules.unitCode.name,
                            placeholder: _vm.$t(
                              _vm.formRules.unitCode.placeholder
                            ),
                            showSearch: true,
                            "option-filter-prop": "children",
                            "filter-option": _vm.filterOption,
                            loading: _vm.loading.loadingUnitCode,
                            allowClear: true
                          },
                          on: {
                            search: function(value) {
                              return _vm.getUnitCode(value)
                            }
                          }
                        },
                        _vm._l(_vm.dataUnitCode, function(data, index) {
                          return _c(
                            "a-select-option",
                            { key: index, attrs: { value: data.unitCode } },
                            [
                              _c(
                                "a-tooltip",
                                [
                                  _c("template", { slot: "title" }, [
                                    _vm._v(" " + _vm._s(data.unitCode) + " ")
                                  ]),
                                  _vm._v(" " + _vm._s(data.unitCode) + " ")
                                ],
                                2
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    {
                      attrs: {
                        "label-align": "right",
                        label: _vm.$t(_vm.formRules.date.label)
                      }
                    },
                    [
                      _c("a-range-picker", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: _vm.formRules.date.decorator,
                            expression: "formRules.date.decorator"
                          }
                        ],
                        attrs: {
                          ranges: {
                            Today: [_vm.moment(), _vm.moment()],
                            "This Month": [
                              _vm.moment(),
                              _vm.moment().endOf("month")
                            ]
                          },
                          name: _vm.formRules.date.name,
                          format: _vm.DEFAULT_DATE_FORMAT,
                          type: "date"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    {
                      attrs: {
                        "label-align": "right",
                        label: _vm.$t(_vm.formRules.finish.label)
                      }
                    },
                    [
                      _c(
                        "a-select",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: _vm.formRules.finish.decorator,
                              expression: "formRules.finish.decorator"
                            }
                          ],
                          attrs: {
                            name: _vm.formRules.finish.name,
                            placeholder: _vm.$t(
                              _vm.formRules.finish.placeholder
                            ),
                            "option-filter-prop": "children",
                            "filter-option": _vm.filterOption,
                            allowClear: true
                          }
                        },
                        _vm._l(_vm.dataFinish, function(data, index) {
                          return _c(
                            "a-select-option",
                            { key: index, attrs: { value: data.key } },
                            [
                              _c(
                                "a-tooltip",
                                [
                                  _c("template", { slot: "title" }, [
                                    _vm._v(" " + _vm._s(data.value) + " ")
                                  ]),
                                  _vm._v(" " + _vm._s(data.value) + " ")
                                ],
                                2
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                [
                  _c(
                    "a-button",
                    {
                      attrs: {
                        type: "primary",
                        loading: _vm.loading.loadingFind,
                        "html-type": "submit"
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_find")) + " ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-row",
        { attrs: { gutter: [16, 16] } },
        [
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _c("TableCustom", {
                attrs: {
                  size: "small",
                  dataSource: _vm.dataSource,
                  columns: _vm.columnsTable,
                  paginationcustom: true,
                  defaultPagination: false,
                  scroll: { x: "calc(700px + 50%)", y: 700 }
                },
                on: { "on-view": _vm.responseViewTable }
              })
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 12 } },
            [
              _c("Pagination", {
                attrs: {
                  total: _vm.totalData,
                  pageSizeSet: _vm.limit,
                  idPagination: "pagination1"
                },
                on: {
                  "response-pagesize-change": _vm.responsePageSizeChange,
                  "response-currentpage-change": _vm.responseCurrentPageChange
                }
              })
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 12, align: "end" } },
            [
              _c("a-tag", { attrs: { color: "grey" } }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("lbl_total_found")) +
                    " : " +
                    _vm._s(_vm.totalData) +
                    " "
                )
              ])
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 8, offset: 16 } },
            [
              _c(
                "a-form",
                { attrs: { form: _vm.form } },
                [
                  _c(
                    "a-form-item",
                    {
                      attrs: { label: _vm.$t(_vm.formRules.totalQtyWo.label) }
                    },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: _vm.formRules.totalQtyWo.decorator,
                            expression: "formRules.totalQtyWo.decorator"
                          }
                        ],
                        attrs: {
                          name: _vm.formRules.totalQtyWo.name,
                          placeholder: _vm.$t(
                            _vm.formRules.totalQtyWo.placeholder
                          ),
                          autocomplete: "off",
                          disabled: true
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    {
                      attrs: {
                        label: _vm.$t(_vm.formRules.totalQtyWoxCogs.label)
                      }
                    },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: _vm.formRules.totalQtyWoxCogs.decorator,
                            expression: "formRules.totalQtyWoxCogs.decorator"
                          }
                        ],
                        attrs: {
                          name: _vm.formRules.totalQtyWoxCogs.name,
                          placeholder: _vm.$t(
                            _vm.formRules.totalQtyWoxCogs.placeholder
                          ),
                          autocomplete: "off",
                          disabled: true
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    {
                      attrs: {
                        label: _vm.$t(_vm.formRules.totalQtyResult.label)
                      }
                    },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: _vm.formRules.totalQtyResult.decorator,
                            expression: "formRules.totalQtyResult.decorator"
                          }
                        ],
                        attrs: {
                          name: _vm.formRules.totalQtyResult.name,
                          placeholder: _vm.$t(
                            _vm.formRules.totalQtyResult.placeholder
                          ),
                          autocomplete: "off",
                          disabled: true
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    {
                      attrs: {
                        label: _vm.$t(_vm.formRules.totalQtyResultxCogs.label)
                      }
                    },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: _vm.formRules.totalQtyResultxCogs.decorator,
                            expression:
                              "formRules.totalQtyResultxCogs.decorator"
                          }
                        ],
                        attrs: {
                          name: _vm.formRules.totalQtyResultxCogs.name,
                          placeholder: _vm.$t(
                            _vm.formRules.totalQtyResultxCogs.placeholder
                          ),
                          autocomplete: "off",
                          disabled: true
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-row",
        { attrs: { gutter: [16, 16] } },
        [
          _c(
            "a-col",
            { attrs: { span: 24, align: "end" } },
            [
              _c(
                "a-space",
                [
                  _c(
                    "a-button",
                    {
                      attrs: { type: "primary", loading: _vm.loading.download },
                      on: { click: _vm.handleDownload }
                    },
                    [_vm._v(_vm._s(_vm.$t("lbl_download")))]
                  ),
                  _c(
                    "a-button",
                    {
                      attrs: { type: "primary", loading: _vm.loading.print },
                      on: { click: _vm.handlePrint }
                    },
                    [_vm._v(_vm._s(_vm.$t("lbl_print")))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }